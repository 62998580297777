import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/context/privateRoute"
import Premium from "../../components/app/premium"
import Headbar from "../../components/app/app-components/headbar"

const AppPremium = ({ path }) => {
  return (
    <div className="h-screen py-5 pt-16 overflow-y-scroll bg-gray-100 hideScrollbar">
      <Headbar title="Premium" path={path} isBackButton={true} />
      <Router>
        <PrivateRoute path="/app/premium" component={Premium} />
      </Router>
    </div>
  )
}

export default AppPremium
