import { useStaticQuery, graphql } from "gatsby"

export const useSubscriptionPlansQuery = () => {
  const data = useStaticQuery(query)
  return data.allStrapiSubscriptionPlans.nodes
}

export const query = graphql`
  {
    allStrapiSubscriptionPlans(filter: { isActive: { eq: true } }) {
      nodes {
        id
        displayName
        basePrice
        isActive
        planFeatures
        examgroups {
          id
          name
          isActive
        }
        durations {
          id
          isActive
          durationMonths
          price
          basePrice
        }
      }
    }
  }
`
