import React from "react"
import { BiRupee } from "react-icons/bi"

export const PaymentButtonCard = ({ Cart }) => {
  return (
    <div className="flex justify-between w-full pb-3">
      <div className="flex flex-col justify-center items-between font-base">
        <div className="flex">
          <p className="flex items-center text-3xl ">
            <BiRupee /> {Cart.price}
          </p>
        </div>
        <p className="font-sans text-xs">(incl of all taxes)</p>
      </div>
      <div className="flex ">
        <button className="px-16 py-6 mt-2 text-xl btn font-base">Pay</button>
      </div>
    </div>
  )
}
