import React, { useState, useEffect } from "react"
import { PaymentButtonCard } from "./PaymentButtonCard"
import { PremiumPlanCard } from "./PremiumPlanCard"
import { GiFallingStar } from "react-icons/gi"
import { motion } from "framer-motion"
import { myAnimations } from "../../context/framer-animations"
import GroupSubsCard from "../app-components/GroupSubsCard"
import { useSubscriptionPlansQuery } from "../../context/static-queries"
import _ from "lodash"

const Premium = ({ group = "Graduation" }) => {
  const subscriptionPlansData = useSubscriptionPlansQuery()
  let groupSubscription = subscriptionPlansData

  if (group.length) {
    groupSubscription = _.filter(
      groupSubscription,
      plan => plan.examgroups[0].name === group
    )
  }

  const subscriptionPlans = groupSubscription[0]

  const defaultActivePlan = _.filter(subscriptionPlans?.durations, {
    durationMonths: 12,
  })
  const initialState = {
    ...defaultActivePlan[0],
    subscribedGroup: group,
    userEmail: "",
  }

  const [Cart, setCart] = useState(initialState)

  useEffect(() => {
    setCart(initialState)
  }, [])

  console.log(Cart)

  return (
    <motion.div
      {...myAnimations.fadeIn}
      className="m-5 space-y-4 select-none "
    >
      <GroupSubsCard examGroupNames={[group]} />
      <div className="mb-4 font-base">Choose your Plan</div>
      {subscriptionPlans?.durations?.map(plan => (
        <PremiumPlanCard
          key={plan.id}
          plan={plan}
          basePrice={subscriptionPlans.durations[0].price}
          Cart={Cart}
          setCart={setCart}
        />
      ))}
      <PromoCard />
      <div className="fixed inset-x-0 bottom-0 z-50 p-4 bg-white rounded-t">
        <PaymentButtonCard Cart={Cart} />
      </div>
    </motion.div>
  )
}

function PromoCard() {
  return (
    <div className="flex flex-col items-center pb-24 mt-4">
      <div className="flex text-red-800 ">
        <GiFallingStar className="w-4 mr-2" />
        Limited Time Introductory Offer
      </div>
      <span className="text-green-800 ">
        Enjoy your premium subscription for free
      </span>
    </div>
  )
}

export default Premium
