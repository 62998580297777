import React from "react"
import _ from "lodash"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useExamGroupsQuery } from "../../../components/context/static-queries/useExamGroupsQuery"
import { BiRupee } from "react-icons/bi"

const ExamGroupsCard = ({ examGroupNames = [], examId = "" }) => {
  const examGroups = useExamGroupsQuery()
  let filterExamGroups = examGroups

  if (examGroupNames.length) {
    filterExamGroups = _.filter(filterExamGroups, exam => {
      return examGroupNames.some(examgroup => examgroup === exam.name)
    })
  }

  if (examId.length) {
    filterExamGroups = _.filter(filterExamGroups, exam => {
      return exam.exams.some(examlist => examlist.examId === examId)
    })
  }

  return (
    <div className="space-y-4">
      {filterExamGroups.map(exam => (
        <ListComponent
          key={exam.id}
          title={exam.name}
          examGroup={exam.name}
          url="/app/exams"
          exam={exam}
          icon={exam.icon?.localFile.childImageSharp.gatsbyImageData}
        />
      ))}
    </div>
  )
}

function ListComponent({ title, icon, exam }) {
  const plan = exam.subscription_plans[0]
  const pricePerMonthArray = plan.durations.map(item =>
    Math.ceil(item.price / item.durationMonths)
  )
  const priceStartsFrom = Math.min(...pricePerMonthArray)

  console.log(exam)

  return (
    <div
      className={`flex p-4 relative bg-white shadow rounded-xl justify-between items-center  `}
    >
      <div className="absolute bottom-0 z-30 hidden px-2 text-xs text-red-500 bg-red-200 bg-opacity-50 left-6 rounded-t-md">
        50% off
      </div>
      <div className="flex items-center justify-start w-full ">
        <div className="flex flex-col items-center justify-center object-contain w-24 pr-6 ">
          {icon && <GatsbyImage image={icon} alt={title} />}
        </div>
        <div className="flex flex-col ">
          <p className="text-xl font-base ">{title}</p>
          <p className="text-gray-500 font-base text-md">
            {exam.exams?.length}+ exams
          </p>
          <div className="flex items-center text-sm ">
            Starts from <BiRupee className="ml-1 -mr-1" />
            {priceStartsFrom}/month
          </div>
          <div className="flex hidden">
            {exam.exams.splice(0, 4).map(item => (
              <div className="w-10 h-10 p-2 mr-1 bg-blue-500 rounded-full">
                <GatsbyImage
                  image={item.icon?.localFile.childImageSharp.gatsbyImageData}
                  alt={title}
                  height={8}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExamGroupsCard
