import React from "react"
import { RiCheckboxBlankCircleFill, RiCheckboxCircleFill } from "react-icons/ri"
import { BiRupee } from "react-icons/bi"
import { format, addDays } from "date-fns"

export const PremiumPlanCard = ({ Cart, setCart, plan, basePrice }) => {
  const perMonthPrice = (plan.price / plan.durationMonths).toFixed(2)
  const mrp = basePrice * plan.durationMonths
  const savingRate = ((mrp - plan.price) / mrp) * 100
  const isSelected = Cart.id === plan.id

  //calculate plan validity
  const today = new Date()
  const addMonths = addDays(today, plan.durationMonths * 30)
  const validTill = format(addMonths, "dd MMM yyyy")

  return (
    <div
      onClick={() => setCart({ ...Cart, ...plan, validTill: addMonths })}
      className={`select-none relative flex justify-between p-4 pt-6  shadow rounded-xl  ${
        isSelected
          ? "bg-green-50 bg-opacity-50 ring-2 ring-green-400"
          : "bg-white"
      }`}
    >
      {isSelected ? (
        <div className="flex items-start mr-2">
          <RiCheckboxCircleFill className="w-6 h-6 text-green-400" />
        </div>
      ) : (
        <div className="flex items-start mr-2">
          <RiCheckboxBlankCircleFill className="w-6 h-6 text-gray-200" />
        </div>
      )}
      {savingRate > 0 && (
        <span className="absolute top-0 px-1 ml-1 text-xs text-white bg-green-400 rounded-b right-4 ">
          {Math.floor(savingRate)}% OFF
        </span>
      )}
      <div className="flex justify-between w-full focus:outline-none">
        <div className="flex flex-col">
          <div className="text-xl rounded font-base">
            {plan.durationMonths} Months
          </div>
          {savingRate > 0 && (
            <p className="hidden text-xs">
              Save
              <span className="px-1 ml-1 text-white bg-green-400 rounded">
                {Math.floor(savingRate)}%
              </span>
            </p>
          )}
          <p className="mt-1 text-xs text-gray-500">
            Pack valid till {validTill}{" "}
          </p>
        </div>
        <div className="flex flex-col items-end justify-start">
          <div className="flex items-center ">
            {/* <p className="flex items-center mr-2 line-through">
              <BiRupee />
              {plan.basePrice}
            </p> */}
            <p className="flex items-center text-2xl font-base">
              <BiRupee />
              {Math.ceil(perMonthPrice)}
              <span className="font-sans text-sm">/month</span>
            </p>
          </div>
          <p className="flex items-center mr-1 text-sm">
            <span className="text-sm">Total</span>
            <BiRupee className="ml-1 -mr-1" />
            <span className="text-sm ">{plan.price}</span>
          </p>
        </div>
      </div>
    </div>
  )
}
